<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <button type="button" class="btn btn-primary mb-2 mr-2 float-left" @click="goBack()">
                            <i class="mdi mdi-arrow-left mr-1"></i> Go back
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <h2>Group: {{ this.selected_mailing_list_details.name }}</h2>
                        </div>
                        <div>
                            <b-button @click="sendMailSwal" variant="success">Send Mailing List</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <!-- <div class="mb-4">
                        <h2>Group: {{ this.selected_product_group_details.name }}</h2>
                    </div> -->
                    <div>
                        <b-tabs content-class="mt-3">
                            <b-tab @click="checkTab()" title="Link Clients to Mailing List" active>
                                <MailingListTable />
                            </b-tab>
                            <!-- <b-tab @click="checkTab()" title="Logs">
                                <MailingListLogTable />
                            </b-tab> -->
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { crmComputed, crmMethods } from "@/state/helpers";
import MailingListTable from "./tables/mailingListTable.vue";
//import MailingListLogTable from "./tables/mailingListLogTable.vue";

//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    data: () => ({
        products: [],
    }),
    components: { 
        MailingListTable, 
        //MailingListLogTable 
    },
    computed: {
        ...crmComputed,
    },
    methods: {
        ...crmMethods,
        checkTab() {
            this.$nextTick(() => {
                window.webix.$$("mailingListTable").refresh();
                window.webix.$$("mailingListLogTable").refresh();
                // window.webix.$$("groupPricingTable").refresh();
            });
        },
        goBack() {
            this.$router.push("/mailing-lists/");
        },
        sendMailSwal() {
            Swal.fire({
                title: `Send Mail?`,
                text: `Are you sure?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    this.sendMailingList({id: this.$route.params.mailinglistid}).then(() => {
                        Swal.fire(`Mailing List Sent`, "", "success");
                    });
                }
            });
        },
    },
    mounted() {},
};
</script>
<style></style>
