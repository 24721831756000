<template>
    <div>
        <div v-if="selected_mailing_list_loading_status == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading Selected Mailing List...
        </div>
        <div v-else>
            <b-overlay :show="show">
                <div class="d-flex justify-content-end">
                    <button v-if="showButton" @click="updateMailingList" type="button" class="btn btn-success mb-2 mr-2">Update Mailing List</button>
                </div>
                <div>
                    <webix-ui :config="ui" v-model="selected_mailing_lists_all_clients"></webix-ui>
                    <div id="mailingListTablePaging"></div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import { crmMethods, crmComputed } from "@/state/helpers";
import { cloneDeep } from "lodash";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    data: () => ({ clientIdsToSend: [], show: false, showButton: false }),
    computed: {
        ...crmComputed,
        selected_mailing_lists_all_clients() {
            return cloneDeep(this.selected_mailing_list);
        },
        ui() {
            //eslint-disable-next-line
            let el = this;
            //eslint-disable-next-line
            let ui = {
                id: "mailingListTable",
                view: "datatable",
                css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                minHeight: 60,
                scrollY: false,
                scrollX: true,
                resizeColumn: true,
                select: false,
                pager: {
                    template: "{common.prev()} {common.pages()} {common.next()}",
                    container: "mailingListTablePaging",
                    size: 20,
                    group: 5,
                },
                columns: [
                    {
                        id: "checkbox",
                        header: { content: "masterCheckbox" },
                        checkValue: "on",
                        uncheckValue: "off",
                        template: "{common.checkbox()}",
                        width: 40,
                    },
                    {
                        id: "company_name",
                        header: ["Company Name", { content: "textFilter" }],
                        tooltip: false,
                        sort: "text",
                        fillspace: 1.8,
                    },
                    {
                        id: "contact_person",
                        header: ["Contact Person", { content: "textFilter" }],
                        tooltip: false,
                        fillspace: 1,
                    },
                    {
                        id: "contact_no",
                        header: ["Contact No", { content: "textFilter" }],
                        tooltip: false,
                        fillspace: 1,
                    },
                    /*{
                        id: "company_reg",
                        header: ["Company Reg", { content: "textFilter" }],
                        tooltip: false,
                        sort: "text",
                    },
                    {
                        id: "vat_no",
                        header: ["Vat No", { content: "textFilter" }],
                        tooltip: false,
                        sort: "text",
                    },
                    {
                        id: "customer_no",
                        header: ["Customer No", { content: "textFilter" }],
                        tooltip: false,
                        sort: "text",
                    },*/
                    {
                        id: "type",
                        header: ["Type", { content: "selectFilter" }],
                        tooltip: false,
                        fillspace: 1,
                        sort: "text",
                    },
                    {
                        id: "address",
                        header: ["Address", { content: "textFilter" }],
                        tooltip: false,
                        fillspace: 1.4,
                        sort: "text",
                    },
                    {
                        id: "emails",
                        header: ["Email", { content: "textFilter" }],
                        tooltip: false,
                        fillspace: 1.4,
                        sort: "text",
                    },
                    {
                        id: "city",
                        header: ["City", { content: "textFilter" }],
                        tooltip: false,
                        fillspace: 1,
                        sort: "text",
                    },
                    {
                        id: "province",
                        header: ["Province", { content: "selectFilter" }],
                        tooltip: false,
                        fillspace: 1.2,
                        sort: "text",
                    },
                ],
                ready: function () {
                    this.data.each(function (obj) {
                        if (obj.is_linked) {
                            this.getItem(obj.id).checkbox = "on";
                            this.refresh(obj.id);
                        }
                    });
                },
                on: {
                    //eslint-disable-next-line
                    onCheck: function (type, message, args) {
                        const getId = type;

                        el.showButton = true;

                        if (args == "on") {
                            let index = el.clientIdsToSend.indexOf(getId);
                            if (index === -1) el.clientIdsToSend.push(getId);
                        }

                        if (args == "off") {
                            let index = el.clientIdsToSend.indexOf(getId);

                            el.clientIdsToSend.splice(index, 1);
                        }
                    },
                },
            };
            return ui;
        },
    },
    methods: {
        ...crmMethods,
        showOverlay(flag) {
            return (this.show = flag);
        },
        //eslint-disable-next-line
        updateMailingList(item) {
            Swal.fire({
                title: `Update mailing list?`,
                text: `Are you sure?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    this.saveMailingListClients({
                        id: this.$route.params.mailinglistid,
                        client_ids: this.clientIdsToSend,
                    }).then(() => {
                        Swal.fire(`Mailing List updated`, "", "success");
                        //refreshes the table on the screen
                        this.setSelectedMailingList({ id: this.$route.params.mailinglistid });
                    });
                }
            });
        },

        initialComponentLoad() {
            const id = this.$route.params.mailinglistid;

            this.setSelectedMailingList({ id: id }).then(() => {
                // set the current is linked products
                for (const client of this.selected_mailing_list) {
                    if (client.is_linked == true) {
                        this.clientIdsToSend.push(client.id);
                    }
                }
            });
        },
    },
    mounted() {
        // this.setSelectedMailingList({ id: this.$route.params.mailinglistid });
        this.initialComponentLoad();
    },
};
</script>

<style lang="scss">
.pointer {
    cursor: pointer;
}
</style>
